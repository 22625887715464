import { Directive, ElementRef, HostListener, inject, Input, OnInit } from "@angular/core";
import { SideMenuComponent } from "../../layouts/components/side-menu/side-menu.component";
import { SideMenuService } from "../../layouts/components/side-menu/services/side-menu.service";

@Directive({
  selector: '[tooltip]',
  standalone: true
})
export class Tooltip implements OnInit {
  @Input('appTooltip') content!: string;
  @Input() placement!: string;
  @Input() value!: string;
  
  tooltip!: HTMLElement;
  tooltipParent!: HTMLElement;
  offset = 10;
  sideMenuOpened = false;

  private _ElementRef = inject(ElementRef);
  private _SideMenuService = inject(SideMenuService);


  ngOnInit(): void {
    this._SideMenuService.sideMenuOpened_BS.subscribe(
      res => {
        console.log('res: ', res);
        this.sideMenuOpened = res;
      }
    )
  }

  @HostListener('mouseenter', ['$event']) onMouseEnter(e: any) {
    if (!this.sideMenuOpened) {
      this.tooltipParent = e.target;
      if (!this.tooltip) {
        this.show();
      }
    }
  }

  show() {
    if (!this.sideMenuOpened) {
      this.create();
      // this.setPosition();
      setTimeout(() => {
        if (this.tooltip) this.tooltip.style.opacity = '1';
      }, 100);
    }
  }


  @HostListener('mouseleave') onMouseLeave() {
    if (!this.sideMenuOpened) {
      if (this.tooltip) {
        this.hide();
      }
    }
  }

  hide() {
    if (!this.sideMenuOpened) {
      this.tooltip.style.opacity = '0'
      setTimeout(() => {
        this.tooltip?.remove();
        this.tooltip = null!;
      }, 300);
    }
  }

  create() {
    if (!this.sideMenuOpened) {
      this.tooltip = document.createElement('div');
      this.tooltip.classList.add(...['tooltip', `position-${this.placement.toLowerCase()}`]);
      this.tooltip.textContent = this.value ? this.value : this.content;
      this.tooltipParent.appendChild(this.tooltip);
    }
  }



}